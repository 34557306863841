import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

function isAllowed(moduleName) {
  if (localStorage.getItem('menuPermissions')) {
    if (JSON.parse(localStorage.getItem('menuPermissions')).includes(moduleName)) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}

function isAllowedParent(moduleName) {
  if (localStorage.getItem('menuPermissionsParent')) {
    if (JSON.parse(localStorage.getItem('menuPermissionsParent')).includes(moduleName)) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}
export const menu = [
  // {
  //   id: 'WORKFLOW',
  //   type: 'section',
  //   title: 'Workflow',
  //   icon: 'package',
  //   hidden: isAllowedParent('workflow'),
  //   children: [
  //     {
  //       id: 'workflow',
  //       title: 'Workflow',
  //       type: 'item',
  //       icon: 'message-square',
  //       url: 'apps/master/workflow',
  //       hidden: isAllowed('workflow'),
  //     },
  //     // {
  //     //   id: 'assign-workflow',
  //     //   title: 'Assign Workflow',
  //     //   type: 'item',
  //     //   icon: 'message-square',
  //     //   url: 'apps/workflow/assign-workflow',
  //     //   hidden: isAllowed('assign-workflow'),
  //     // }
  //   ]
  // },
  {
    id: 'MASTERS',
    type: 'section',
    title: 'Masters',
    // translate: 'MENU.APPS.SECTION',
    icon: 'package',
    hidden: isAllowedParent('masters'),
    children: [
      {
        id: 'csv-file-master',
        title: 'Csv File Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/csv-file-master',
        hidden: isAllowed('csv file master'),
      },
      {
        id: 'users',
        title: 'User Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/user',
        hidden: isAllowed('user master'),
      },
      {
        id: 'roles',
        title: 'Role Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/roles',
        hidden: isAllowed('role master'),
      },
      {
        id: 'workflow',
        title: 'Workflow Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/workflow',
        hidden: isAllowed('workflow master'),
      },
      {
        id: 'country',
        title: 'Country Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/country',
        hidden: isAllowed('country master'),
      },
      {
        id: 'country-code',
        title: 'Country Code Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/country-code',
        hidden: isAllowed('country-code master'),
      },
      {
        id: 'company',
        title: 'Company Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/company',
        hidden: isAllowed('company master'),
      },
      {
        id: 'location',
        title: 'Location Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/location',
        hidden: isAllowed('location master'),
      },
      {
        id: 'department',
        title: 'Department Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/department',
        hidden: isAllowed('department master'),
      },
      {
        id: 'currency',
        title: 'Currency Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/currency',
        hidden: isAllowed('currency master'),
      },
      {
        id: 'currency rate',
        title: 'Currency Rate Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/currency-rate',
        hidden: isAllowed('currency rate master'),
      },
      {
        id: 'category approval',
        title: 'Category Approval Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/category-approval',
        hidden: isAllowed('category approval master'),
      },
      {
        id: 'plant approval',
        title: 'Plant Approval Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/plant-approval',
        hidden: isAllowed('plant approval master'),
      },
      {
        id: 'asset master',
        title: 'Asset Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/asset',
        hidden: isAllowed('asset master'),
      },
      {
        id: 'budgeted cost master',
        title: 'Budgeted Cost Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/budgeted-cost',
        hidden: isAllowed('budgeted cost master'),
      },
      {
        id: 'budget cost master',
        title: 'Budget Cost Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/budget-cost',
        hidden: isAllowed('budget cost master'),
      },
      {
        id: 'profit center master',
        title: 'Profit Center Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/profit-center',
        hidden: isAllowed('profit center master'),
      },
    ]
  },
  {
    id: 'TRANSACTIONS',
    type: 'section',
    title: 'Transactions',
    icon: 'package',
    hidden: isAllowedParent('transactions'),
    children: [
      {
        id: 'initiator-form',
        title: 'Initiator Form',
        type: 'item',
        icon: 'message-square',
        url: 'apps/transactions/initiator-form',
        hidden: isAllowed('initiator form'),
      },
      {
        id: 'my-requests',
        title: 'My Requests',
        type: 'item',
        icon: 'message-square',
        url: 'apps/transactions/my-requests',
        hidden: isAllowed('my requests'),
      },
      {
        id: 'my-approvals',
        title: 'My Approvals',
        type: 'item',
        icon: 'message-square',
        url: 'apps/transactions/my-approvals',
        hidden: isAllowed('my approvals'),
      },
      {
        id: 'global-user-login',
        title: 'Global User Login',
        type: 'item',
        icon: 'message-square',
        url: 'apps/transactions/global-user-login',
        hidden: isAllowed('global user login'),
      }
    ]
  },
  {
    id: 'REPORTS',
    type: 'section',
    title: 'Reports',
    icon: 'package',
    hidden: isAllowedParent('reports'),
    children: [
      {
        id: 'trans-details-report',
        title: 'CER Task Report',
        type: 'item',
        icon: 'message-square',
        url: 'apps/reports/trans-report',
        hidden: isAllowed('trans details report'),
      },
      {
        id: 'cer-format-report',
        title: 'Download CER Format',
        type: 'item',
        icon: 'message-square',
        url: 'apps/reports/cer-report',
        hidden: isAllowed('cer format report'),
      },
      {
        id: 'aging-report',
        title: 'CER Aging Report',
        type: 'item',
        icon: 'message-square',
        url: 'apps/reports/aging-report',
        hidden: isAllowed('aging report'),
      },
      {
        id: 'audit-report',
        title: 'CER Audit Report',
        type: 'item',
        icon: 'message-square',
        url: 'apps/reports/audit-report',
        hidden: isAllowed('audit report'),
      }
    ]
  },
];


