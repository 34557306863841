import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  token: any;
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _authenticationService: AuthenticationService, private router:Router) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._authenticationService.returnLocalStorageData().subscribe(res=>{
      if(res){
        this.token = res['token'];
      }
		});
    // const currentUser = this._authenticationService.currentUserValue;
    const isLoggedIn =this.token;
    // const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn) {
      request = request.clone({
        setHeaders: {
          Authorization: `jwt ${this.token}`
        }
      });
    }

    return next.handle(request).pipe(
      tap(),
      catchError(
        err => {
          console.log(err);
          
					if (err == 'User token is expired') {
						localStorage.clear();
						this.router.navigate(['/login/login-type']);
						// this.router.navigate(['/pages/authentication/login-v2']);
					}
					throw err
				}
      )

    );
  }
}
